



import Vue from 'vue'
// import EZUIKit from 'ezuikit-js'
// import EZUIKit from '../../../../public/static/lib/ezuikit.js'

import moment from 'moment/moment'
import request from '@/utils/request'
export default Vue.extend({
    props: {
        id: {
            type: String,
        },
        url: {
            type: String,
        },
    },
    data() {
        return {
            videoList: []as any,
        }
    },
    mounted() {
        // @ts-ignore
        const video = new window.EZUIKit.EZUIPlayer(this.id)
        this.videoList.push(video)
    },
    destroyed() {
        for (const i of this.videoList) {
            i.stop();
        }
    },
})
