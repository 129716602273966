





























import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import DocDiv from './docView.vue'
import videoPlayer from './videoPlayer.vue'
export default Vue.extend({
    components: {
        DocDiv, videoPlayer,
    },
    data() {
      return {
          id:'',
          data: {},
          DocViewShow: false,
      }
    },
    async created() {
        // console.log(this.$route.params)
        this.id = this.$route.params.id
        await this.getData()
        // this.updatedImage()
        console.log(JSON.parse(JSON.stringify(this.data)))
    },
    methods: {
        getData() {
            return request.get(`/api/column/rest_article/${this.id}`).then((res: any) => {
                if (res.enclosure) {
                    const arr1 = res.enclosure.split('/')
                    const str1 = arr1[arr1.length - 1]
                    const arr2 = str1.split('.')
                    res.enclosureStr = arr2[0]
                    res.enclosureType = res.enclosure.split('.')[1]
                    this.DocViewShow = true
                }
                this.data = res
            })
        },
        updatedImage() {
            let content = {} as any
            content = document.getElementById('content')
            // console.log(content)
            const imgs = content.getElementsByTagName('img')
            // console.log(imgs)
            for (const i of imgs) {
                // console.log(i)
                // console.log(i.parentNode)
                // i.parentNode.style.background = 'red'
                i.parentNode.parentNode.style.position = 'relative'
                i.parentNode.style.display = 'inline-block'
                i.parentNode.style.position = 'relative'
                i.parentNode.style.left = '50%'
                i.parentNode.style.transform = 'translateX(-50%)'
                i.style.display = 'block'
                i.style.width = '100%'
                i.style.height = '100%'
            }
        },
        view(value) {
            // console.log(value)
            const type = value.split('.')[1]
            // console.log(type)
            if (type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'mp4') {
                let baseUrl = ''
                if (process.env.NODE_ENV=='development') {
                    baseUrl = 'http://ylwx.sxktqx.com'
                }
                window.location.href = `${baseUrl}/columnfiles${value}`
            } else {
                this.$router.push({
                    path: '/doc_view',
                    query: {url: `https://ylwx.sxktqx.com/files${value}`, type},
                })
              // window.location.href = `http://ylwx.sxktqx.com/files${value}`
            // } else {
            //     this.$notify('暂不支持该格式')
            }
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            return time.format('YYYY-MM-DD')
        }
    }
})
